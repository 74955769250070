<template>
  <el-card v-loading="loading" shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
    <el-row :gutter="20">
      <el-col :span="14">
        <el-form v-loading="loading" :model="form" label-width="8rem" style="padding: 2rem;">
          <el-form-item label="七牛云远程附件">
            <el-radio v-model="form.qi_niu_status" :label="false">关闭</el-radio>
            <el-radio v-model="form.qi_niu_status" :label="true">开启</el-radio>
          </el-form-item>
          <el-form-item label="Accesskey">
            <el-input v-model="form.qi_niu_access_key"></el-input>
            <div class="y-desc">用于签名的公钥</div>
          </el-form-item>
          <el-form-item label="Secretkey">
            <el-input v-model="form.qi_niu_secret_key"></el-input>
            <div class="y-desc">用于签名的私钥</div>
          </el-form-item>
          <el-form-item label="Bucket">
            <el-input v-model="form.qi_niu_bucket"></el-input>
            <div class="y-desc">请保证bucket为可公共读取的</div>
          </el-form-item>
          <el-form-item label="Url">
            <el-input v-model="form.qi_niu_url"></el-input>
            <div class="y-desc">七牛支持用户自定义访问域名。注：url开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
          </el-form-item>
          <el-form-item >
            <el-button @click="test">测试配置(测试无需开启，修改配置需保存后测试)</el-button>
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col v-if="form.id" :span="8">
        <div style="padding: 2rem;width: 100%">
          <div style="margin-bottom: 2rem;text-align: right">
            <el-button @click="local2remote">一键上传本地附件至七牛</el-button>
          </div>
          <div>
            <el-form label-width="6rem">
              <el-form-item label="视频压缩码率">
                <el-input-number v-model="form.transcode_vb" :min="10" :max="60000"></el-input-number>
                <div class="y-desc">影响压缩体积 常用码率 128 1280 5120</div>
              </el-form-item>
              <el-form-item label="视频压缩宽度">
                <el-input-number v-model="form.transcode_w" :min="0" :max="3840"></el-input-number>
                <div class="y-desc">为0时保持原视频分辨率宽高比例</div>
                <div class="y-desc">指定转码后视频分辨率宽 高等比例自动缩放</div>
                <div class="y-desc" style="margin-top: 1rem">码率越高，越清晰,体积越大</div>
                <div class="y-desc">在码率一定的情况下，分辨率与清晰度成反比关系</div>
                <div class="y-desc">在分辨率一定的情况下，码率与清晰度成正比关系</div>
              </el-form-item>
            </el-form>
          </div>

          <div style="margin-top: 2rem">
            <div>注意事项</div>
            <div class="y-desc" style="margin-bottom: 1rem">七牛压缩视频为按量计费，具体费用使用情况自行进入七牛后台查看，视频可多次操作转码,请注意使用频率</div>
            <div class="y-desc" style="margin-bottom: 1rem">上传任务启动后即可离开此页面，后台将自动上传本地附件直至全部上传完成</div>
            <div class="y-desc" style="margin-bottom: 1rem">一键上传不会删除本地附件文件，仅将本地附件上传至七牛</div>
            <div class="y-desc">富文本内容引用图片为引用完整url，故站点开启远程附件后已保存的富文本内容引用的图片仍为原url</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      form:{
        name:"",
        logo:"",
        tx_map_key:"",
        black_auto:1,
        black_url:"",
        index_share_title:"",
        index_share_desc:"",
        index_share_logo:"",
      },
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    local2remote(){
      this.$api.system.qiniuAllLocal2Remote().then(()=>{
        this.$message.success("上传任务启动成功");
      })
    },
    test(){
      this.$api.system.qiniuTest().then(()=>{
        this.$message.success("远程附件配置正常");
      });
    },
    clearCashierQrcode(){
      this.loading = true;
      this.$api.system.clearCashierQrcodeCache().then(()=>{
        this.$message.success("清理完成");
        this.loading = false;
      })
    },
    load(){
      this.loading = true;
      this.$api.account.info().then(res=>{
        this.form = res.uni_account
        // this.form.name = res.uni_account.name;
        // this.form.logo = res.uni_account.logo;
        // this.form.tx_map_key = res.uni_account.tx_map_key;
        // this.form.black_auto = res.uni_account.black_auto;
        // this.form.black_url = res.uni_account.black_url;
        // this.form.index_share_title = res.uni_account.index_share_title;
        // this.form.index_share_desc = res.uni_account.index_share_desc;
        // this.form.index_share_logo = res.uni_account.index_share_logo;
        this.loading = false;
      })
    },
    submit(){
      this.form.black_auto = parseInt(this.form.black_auto);
      this.$api.account.uniEdit(this.form).then(()=>{
        this.$message.success("操作成功");
      })
    }
  }
}
</script>

<style scoped>
.y-desc{
  line-height: 1rem
}
</style>